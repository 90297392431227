.form{
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    color: $white;


    &-label{
        display: inline-block;
        width: 100%;
      
    }


    &-input{
        margin-top: 8px;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 8px;
            padding: 20px;
        font-family: inherit;
    font-weight: 600;
            font-size: 15px;
            line-height: 1.4;
            color: $accent;
            background: $white;

        transition: $transition;


        &::placeholder{
            color: $second;
        }
    
        &.textarea{
            resize: none;
            border-radius: 8px;
            min-height: 140px;
        }
    
    }

    .btn{
        margin-left: auto;
        max-width: max-content;
     
    }

}