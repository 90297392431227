.hero {
    .content-wrapper{
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
}


.who {
    .content-wrapper{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        @include desktop{
            flex-direction: row;
            justify-content: space-between;
        }
    }
   

}

.services{
    .title2{
        text-align: center;
    }
    ul{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        @include desktop{
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;
        }

    }
    li{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: 500px;
        @include desktop{
            max-width: 49%;
        }
    }
}

.driving{

   ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
   }
   li{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    
    @include desktop{
        flex-direction: row;
        justify-content: space-between;
        
    }
    .text-wrapper{
        padding: 32px;
        background: $black;
        color: $white;
        display: flex;
        gap: 20px;
        flex-direction: column;
        
    }

    .btn{
        margin-top: auto;
        margin-left: auto;
    }
   }
}

.why{
    .title2{
        text-align: center;
    }
    ul{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;

    }
    li{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        @include desktop{
            flex-direction: row;

        }
        .title4{
            margin-bottom: 20px;
        }
    }
}

.contacts{
    background: $black;
    
   
}


// =================== privacy\terms.. page start ================

.privacy {
    background-color: $white;
    color: $black;
    
    .title2{
        margin-bottom: 30px;
    }
   
}


// =================== privacy page end ================


// =================== submit page start ================

.success{

    .content-wrapper{
        min-height: calc( 100vh - 340px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 50px;
    }
  
  
}

// =================== submit page end ================

