.burger{


    &.menu-container {
        position: relative;
    }

    .menu-toggle {
        font-size: 18px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        color: #fff;
    }

    .menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 12;
    }

    .menu-content {
        background: $main-bg;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        position: relative;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 30px;


    }

    .close-menu {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 18px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .menu-container{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    ul{
        margin-top: 50px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }


    .btn{
        max-width: 100%;
        margin-top: auto;
    }
}