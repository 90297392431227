html,
body {

  font-family: $main-font;
  background: $main-bg;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: $text;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;

}


section {


      padding-top:40px;
      padding-bottom:40px;
 


}



// ------------------------------------------  logo start  ------------------------------------------
.logo { 
  display: none;
    font-family: $second-font;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 700;
  font-size: 21px;
  line-height: 0.8;
  letter-spacing: 0.03em;
  text-transform: capitalize;

  text-align: center;
  transition: $transition;
  max-width: max-content;

  &:hover,
  &:focus {
    color: $accent;
  }

  &:active {
    color: inherit;
  }

 
  
}

// ------------------------------------------  logo end  ------------------------------------------

// ------------------------------------------  links start  ------------------------------------------

.link {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  border-bottom: 1px solid transparent;
  padding: 2px 4px;
  font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
  text-align: center;

  transition: $transition;

  width: 100%;
  max-width: max-content;
  &:hover,
  &:focus {
    color: $accent;
    border-bottom: 1px solid $accent;
  }

  &:active {
    color: inherit;
    border-bottom: 1px solid transparent;
  }


}

// ------------------------------------------  links end  ------------------------------------------

// ------------------------------------------  buttons start  ------------------------------------------
.btn {
    font-family: $main-font;
    font-family: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 100%;
      
    max-width: max-content;


  
    cursor: pointer;
      border: none;
border-radius: 6px;
  padding: 16px 48px;

    font-weight: 700;
      font-size: 16px;
      line-height: 1.2;

      text-align: center;
    background: $accent;
      color: $white;

      &-second{
        background: $black;
        color: $white;
 
      }

}


// ------------------------------------------  buttons end  ------------------------------------------

// ------------------------------------------  hover focus active start  ------------------------------------------
.hfa-scale {
  transition: $transition;

  &:hover,
  &:focus {
    transform: scale(1.032);
  }

  &:active {}

  transform: scale(1);
}

// ------------------------------------------  hover focus active end  ------------------------------------------

// ------------------------------------------  titles start  ------------------------------------------

.title1 {
font-weight: 400;
  font-size: 72px;
  line-height: 1.1;

}

.title2 {
font-weight: 600;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
}

.title3{
font-weight: 600;
  font-size: 24px;
  line-height: 1.2;


}

.title4{
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: $accent;
}






// ------------------------------------------  titles end  ------------------------------------------



// ------------------------------------------  other styles start  ------------------------------------------
.decimal{
    list-style: decimal;
      padding-left: 18px;
}

.disc{
   list-style: disc;
   padding-left: 18px;

}


.text-link{
  display: inline;
  text-decoration: underline;
  font-weight: 600;
}

.arrow-up {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 20px;
  color: $accent;
  transition: $transition;
  cursor: pointer;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &:hover,
  &:focus {
    color: $second;
    transform: scale(1.035);
  }

  &:active {
    color: $accent;
    transform: inherit;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

// ------------------------------------------  other styles end ------------------------------------------



// ------------------------------------------  margins start ------------------------------------------


// ------------------------------------------  margins end --------------------------------------------




// ------------------------------------------  form validate styles start ------------------------------------------
.just-validate-error-field.form-input {
  border: 1px solid $error
}

.just-validate-error-label {

  display: none;
}

// ------------------------------------------  form validate styles start ------------------------------------------