.footer{

   
    padding-top: 50px;
    padding-bottom: 20px;


       .navigation{
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include desktop{
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        

       
            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                @include desktop{
                    flex-direction: row;
                }

            }

        p{
            font-weight: 400;
                font-size: 14px;
                line-height: 1.4;
                text-align: center;
        }
       
       }

    }
    
    
  

  
    

