@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 360px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 80px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  fill: currentColor;
}

li:last-child {
  margin-bottom: 0 !important;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Lato", sans-serif;
  background: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #000;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.logo {
  display: none;
  font-family: "Lato", sans-serif;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 700;
  font-size: 21px;
  line-height: 0.8;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  text-align: center;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-width: max-content;
}
.logo:hover, .logo:focus {
  color: #ea2424;
}
.logo:active {
  color: inherit;
}

.link {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  border-bottom: 1px solid transparent;
  padding: 2px 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 100%;
  max-width: max-content;
}
.link:hover, .link:focus {
  color: #ea2424;
  border-bottom: 1px solid #ea2424;
}
.link:active {
  color: inherit;
  border-bottom: 1px solid transparent;
}

.btn {
  font-family: "Lato", sans-serif;
  font-family: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: max-content;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  padding: 16px 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  background: #ea2424;
  color: #fff;
}
.btn-second {
  background: #000;
  color: #fff;
}

.hfa-scale {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: scale(1);
}
.hfa-scale:hover, .hfa-scale:focus {
  transform: scale(1.032);
}
.title1 {
  font-weight: 400;
  font-size: 72px;
  line-height: 1.1;
}

.title2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
}

.title3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
}

.title4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #ea2424;
}

.decimal {
  list-style: decimal;
  padding-left: 18px;
}

.disc {
  list-style: disc;
  padding-left: 18px;
}

.text-link {
  display: inline;
  text-decoration: underline;
  font-weight: 600;
}

.arrow-up {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 20px;
  color: #ea2424;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.arrow-up:hover, .arrow-up:focus {
  color: #ea2424;
  transform: scale(1.035);
}
.arrow-up:active {
  color: #ea2424;
  transform: inherit;
}
.arrow-up svg {
  width: 3rem;
  height: 3rem;
}
.arrow-up.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.just-validate-error-field.form-input {
  border: 1px solid #ef0e0e;
}

.just-validate-error-label {
  display: none;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  color: #000;
  border-bottom: 1px solid #000;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 0px 0px #ea2424;
  -moz-box-shadow: 0px 0px 0px 0px #ea2424;
  box-shadow: 0px 0px 0px 0px #ea2424;
}
.header.fixed {
  -webkit-box-shadow: 0px 4px 18px 0px #ea2424;
  -moz-box-shadow: 0px 4px 18px 0px #ea2424;
  box-shadow: 0px 4px 18px 0px #ea2424;
}
.header .navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 24px 0;
}

.footer {
  padding-top: 50px;
  padding-bottom: 20px;
}
.footer .navigation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .footer .navigation {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.footer .navigation ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 1200px) {
  .footer .navigation ul {
    flex-direction: row;
  }
}
.footer .navigation p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
}

.hero .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.who .content-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .who .content-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.services .title2 {
  text-align: center;
}
.services ul {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 1200px) {
  .services ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }
}
.services li {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 500px;
}
@media screen and (min-width: 1200px) {
  .services li {
    max-width: 49%;
  }
}

.driving ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.driving li {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .driving li {
    flex-direction: row;
    justify-content: space-between;
  }
}
.driving li .text-wrapper {
  padding: 32px;
  background: #000;
  color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.driving li .btn {
  margin-top: auto;
  margin-left: auto;
}

.why .title2 {
  text-align: center;
}
.why ul {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.why li {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .why li {
    flex-direction: row;
  }
}
.why li .title4 {
  margin-bottom: 20px;
}

.contacts {
  background: #000;
}

.privacy {
  background-color: #fff;
  color: #000;
}
.privacy .title2 {
  margin-bottom: 30px;
}

.success .content-wrapper {
  min-height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  color: #fff;
}
.form-label {
  display: inline-block;
  width: 100%;
}
.form-input {
  margin-top: 8px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 20px;
  font-family: inherit;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.4;
  color: #ea2424;
  background: #fff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.form-input::placeholder {
  color: #ea2424;
}
.form-input.textarea {
  resize: none;
  border-radius: 8px;
  min-height: 140px;
}
.form .btn {
  margin-left: auto;
  max-width: max-content;
}

/* Стили для модального окна */
.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 890px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

/* Контент модального окна */
.modal-content {
  border: 1px solid #000;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  position: relative;
  width: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 12px;
  /* Кнопка закрытия */
}
.modal-content .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-content .btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .modal-content .btn-wrapper {
    flex-direction: row;
  }
}
.modal-content .btn-wrapper .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .modal-content .btn-wrapper .btn {
    max-width: 260px;
  }
}
.modal-content .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 44px;
  cursor: pointer;
}

.burger.menu-container {
  position: relative;
}
.burger .menu-toggle {
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: #fff;
}
.burger .menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.burger .menu-content {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.burger .close-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
}
.burger .menu-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.burger ul {
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.burger .btn {
  max-width: 100%;
  margin-top: auto;
}