 .header {

  
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    background: $main-bg;
    transition: $transition;
    color: $text;
    border-bottom: 1px solid $black;


    z-index: 10;

    -webkit-box-shadow: 0px 0px 0px 0px $accent;
    -moz-box-shadow: 0px 0px 0px 0px $accent;
    box-shadow: 0px 0px 0px 0px $accent;
 
    &.fixed {        

         -webkit-box-shadow: 0px 4px 18px 0px $accent;
        -moz-box-shadow: 0px 4px 18px 0px $accent;
        box-shadow: 0px 4px 18px 0px $accent;
      
    }

    .navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 24px 0;
       
    }

}