// font family
$main-font: "Lato", sans-serif;
$second-font: "Lato", sans-serif;


// bg
$main-bg:  #fff;;

// text
$text:  #000;


// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #fff;
$black: #000;
$accent: #ea2424;
$second: #ea2424;

$error: #ef0e0e;

// modal
