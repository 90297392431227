/* Стили для модального окна */
.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 890px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
}


.modal.active {
    opacity: 1;
    visibility: visible;
}

/* Контент модального окна */
.modal-content {
    border: 1px solid $black;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $main-bg;
    position: relative;

    width: 100%;
   
    width: 100%;

    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: 12px;




        .container {
            display: flex;
            flex-direction: column;
            gap: 20px;
        
           
        }
       
.btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @include tablet {
        flex-direction: row;
    }

    .btn {

        max-width: 100%;

        @include tablet {
            max-width: 260px;
        }

    }
}



/* Кнопка закрытия */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 44px;
    cursor: pointer;
}
}


